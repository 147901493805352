<div class="card-responsive card-responsive-small">
  <div class="card-body">
    <form [formGroup]="licensePlateForm">
      <div class="text-center">
        <div class="headline-x-small mb-4" [innerText]="'Onboarding.StraightOutOfTheGarage' | translate"></div>
        <p class="body-medium" [innerText]="'Onboarding.EnterLicensePlateToShowParkingSessions' | translate"></p>
      </div>
      <div class="ar-input-group">
        <arivo-license-plate-input formControlName="licensePlate" #licensePlateInputComponent (keydown)="enterCheck($event)" />
        <p
          class="error"
          *ngIf="licensePlateForm.controls.licensePlate.touched && licensePlateForm.controls.licensePlate.invalid"
          [innerText]="'Common.Errors.LicensePlateIsRequired' | translate"
        ></p>
      </div>
      <div class="ar-input-group my-4 text-left">
        <div class="flex items-center gap-4">
          <input id="confirmRights" type="checkbox" class="switch" formControlName="confirmRights" />
          <label for="confirmRights" class="body-medium" [innerText]="'Onboarding.ConfirmRights' | translate"></label>
        </div>
      </div>
      <arivo-step-footer [center]="true">
        <button type="button" (click)="next()" class="button-primary" [innerText]="'Common.Continue' | translate"></button>
        <button
          type="button"
          [routerLink]="['/login']"
          class="button-primary-outlined"
          [innerText]="'Onboarding.BackToLogin' | translate"
        ></button>
      </arivo-step-footer>
    </form>
  </div>
</div>
